<template>
  <div class="relative w-24 h-10">
    <div class="mask absolute w-full h-full"></div>
    <img class="absolute w-full h-full" src="~~/assets/img/logo/logo.svg">
  </div>
</template>

<style scoped lang="scss">
.mask {
  background-position: 50% 50%;
  background-image: url('~~/assets/img/logo/holo.png');
  background-size: 200% 200%;
  background-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  width: 102%;
  left: -1px;

  mask-image: url('~~/assets/img/logo/mask.svg');
  mask-size: contain;

  animation: 10000ms translateHolo infinite linear;
}
@keyframes translateHolo {
  0%{
    background-position: 50% 0%;
  }
  11.1%{
    background-position: 83% 12%;
  }
  22.2%{
    background-position: 100% 43%;
  }
  33.3%{
    background-position: 94% 78%;
  }
  44.4%{
    background-position: 68% 100%;
  }
  55.5%{
    background-position: 32% 100%;
  }
  66.6%{
    background-position: 6% 78%;
  }
  77.7%{
    background-position: 0% 43%;
  }
  88.8%{
    background-position: 17% 12%;
  }
  100%{
    background-position: 50% 0%;
  }
}
</style>
